import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
} from "@mui/material";
import { CustomOutlineButton } from "../../components/custom-button";

const PreviewDialog = ({ open, onClose, template }) => {
  if (!template) return null;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      className="email-preview-dialog"
    >
      <DialogTitle>
        {template.templateName || `Template ${template.id}`}
      </DialogTitle>
      <DialogContent>
        <Box className="email-preview-dialog__iframe-container">
          <iframe
            srcDoc={template.htmlContent}
            title={`Preview ${template.templateName}`}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <CustomOutlineButton label="Close" onClick={onClose} />
      </DialogActions>
    </Dialog>
  );
};

export default PreviewDialog;

import React, { useState, useEffect } from "react";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import {
  Container,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
} from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { makeStyles } from "@mui/styles";
import { useTemplateList, useSaveTemplate } from "../../hooks/email.hooks";
import EmailEditorDialog from "../../features/contact/email-editor-dialog";
import AddEmailTemplate from "../../features/contact/add-email-template";
import { CustomOutlineButton } from "../../components/custom-button";
import HeaderComponent from "../../components/HeaderComponent";
import PreviewDialog from "./PreviewDialog";
import "../../scss/email-center.scss";
import { useTabStyles } from "../../utils/styles-util";
import { primaryColor } from "../../scss/colors.module.scss";
import { USER_ROLE } from "../../commons/enum";
import { useMyUserRole } from "../../hooks/user.hooks";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  black: {
    color: "#000000",
  },
  unselect: {
    color: "rgba(0, 0, 0, 0.54)",
  },
});

const EmailCenterPage = () => {
  const { data: templateList, isLoading, refetch } = useTemplateList();
  const { mutateAsync: saveTemplateMutation } = useSaveTemplate();
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [previewTemplate, setPreviewTemplate] = useState(null);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [filter, setFilter] = useState("all");
  const classes = useStyles();
  const tabsStyles = useTabStyles();

  const getRoleText = (role) => {
    switch (role) {
      case USER_ROLE.ADMIN:
        return "SYSTEM";
      case USER_ROLE.STAFF:
        return "COMPANY";
      default:
        return "All Users";
    }
  };

  const handleOpenEditor = (template = null) => {
    setSelectedTemplate(template);
    setIsEditorOpen(true);
  };

  const handleDuplicate = async (template) => {
    setIsDuplicating(true);
    try {
      const duplicateTemplate = {
        ...template,
        id: undefined,
        templateName: `${template.templateName} (Copy)`,
      };

      await saveTemplateMutation(duplicateTemplate);
      await refetch();
    } catch (error) {
      console.error("Failed to duplicate template:", error);
    } finally {
      setIsDuplicating(false);
    }
  };

  const handlePreview = (template) => {
    setPreviewTemplate(template);
  };

  const handleClosePreview = () => {
    setPreviewTemplate(null);
  };

  useEffect(() => {
    if (templateList?.length) {
      setSelectedTemplate(templateList[0]);
    }
  }, [templateList]);

  const filteredTemplates = templateList?.filter((template) => {
    if (filter === "all") return true;
    if (filter === "system") return template.user.user_role === USER_ROLE.ADMIN;
    if (filter === "company")
      return template.user.user_role === USER_ROLE.STAFF;
    return true;
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="email-center">
      <HeaderComponent
        headerContent={
          <Tabs
            value={0}
            aria-label="simple tabs example"
            TabIndicatorProps={{ style: { background: primaryColor } }}
            textColor="inherit"
            classes={{
              root: tabsStyles.tabs,
              flexContainer: tabsStyles.tabsFlexContainer,
              indicator: tabsStyles.indicator,
            }}
          >
            <Tab
              label="Email & Automations"
              {...a11yProps(0)}
              classes={{
                textColorInherit: classes.black,
              }}
            />
          </Tabs>
        }
      />
      <Container maxWidth="xl" className="email-center__container">
        <div className="email-center__filters">
          <ToggleButtonGroup
            value={filter}
            exclusive
            onChange={(e, newFilter) => newFilter && setFilter(newFilter)}
            size="small"
          >
            <ToggleButton value="all">All</ToggleButton>
            <ToggleButton value="system">System</ToggleButton>
            <ToggleButton value="company">Company</ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div className="email-center__grid">
          {filteredTemplates?.map((template) => (
            <div key={template.id} className="email-center__card-wrapper">
              <Typography className="email-center__card-title">
                <Tooltip
                  title={template.templateName || `Template ${template.id}`}
                  placement="top"
                  arrow
                >
                  <span className="email-center__card-title-text">
                    {template.templateName || `Template ${template.id}`}
                  </span>
                </Tooltip>
              </Typography>
              <div className="email-center__card">
                <div className="email-center__card-actions">
                  <div className="action-row">
                    <CustomOutlineButton
                      startIcon={<VisibilityOutlinedIcon />}
                      label="Preview"
                      onClick={() => handlePreview(template)}
                      variant="text"
                    />
                    <CustomOutlineButton
                      startIcon={<EditOutlinedIcon />}
                      label="Edit"
                      onClick={() => handleOpenEditor(template)}
                      variant="text"
                    />
                  </div>
                  <div className="action-row">
                    <CustomOutlineButton
                      startIcon={<ContentCopyOutlinedIcon />}
                      label="Duplicate"
                      onClick={() => handleDuplicate(template)}
                      variant="text"
                    />
                    <span className="email-center__role-tag">
                      {getRoleText(template.user.user_role)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="email-center__card-wrapper">
            <Typography className="email-center__card-title">
              <span className="email-center__card-title-text">&nbsp;</span>
            </Typography>
            <AddEmailTemplate onClick={() => handleOpenEditor()} />
          </div>
        </div>

        <EmailEditorDialog
          isOpen={isEditorOpen}
          emailList={[]}
          period={0}
          setIsOpenEmailEditorDialog={setIsEditorOpen}
          selectedTemplate={selectedTemplate}
          source="email-center"
        />

        <PreviewDialog
          open={!!previewTemplate}
          onClose={handleClosePreview}
          template={previewTemplate}
        />
      </Container>
    </div>
  );
};

export default EmailCenterPage;

import React from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const AddEmailTemplate = ({ onClick = () => {} }) => {
  return (
    <div className="email-center__card">
      <Button
        onClick={onClick}
        variant="text"
        startIcon={<AddIcon />}
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: "#666",
          gap: "8px",
          "&:hover": {
            backgroundColor: "#f5f5f5",
          },
        }}
      >
        Add Template
      </Button>
    </div>
  );
};

AddEmailTemplate.propTypes = {
  onClick: PropTypes.func,
};

export default AddEmailTemplate;
